







import { Component, Mixins, Vue } from 'vue-property-decorator'

import QuestionImageHorizontal from '@/components/modules/drillsv3/organisms/QuestionImageHorizontal.vue'
import QuestionImageVertical from '@/components/modules/drillsv3/organisms/QuestionImageVertical.vue'
import Title from '@/components/modules/drillsv3/atoms/Title.vue'
import ErrorMessage from '@/components/atoms/v3/ErrorMessage.vue'
import { PAGE_TYPE } from '@/store/modules/DrillsV3'
import DrillsConfig from '@/mixins/drillsv3/Config'
import DrillWebApi from '@/mixins/drillsv3/WebApi'

@Component({
  components: {
    QuestionImageHorizontal,
    QuestionImageVertical,
    Title,
    ErrorMessage,
  },
})
export default class Drill extends Mixins(DrillWebApi) {
  // 読み込み完了フラグ
  private loadComplete = false

  private error = false

  private drillModules = DrillsConfig.drillModules

  private subjectCode = this.$route.query?.subjectCode || ''

  private get drillModulesByCode() {
    return this.subjectCode === 'ko' ? this.drillModules.DRILL_REPORT_VR : this.drillModules.DRILL_REPORT_HR
  }

  private version = this.$route.params.type

  protected async mounted(): Promise<any> {
    // 読み込み完了フラグを未完了に変更
    const version = ['v2', 'v3'].includes(this.version) ? this.version : 'v3'
    this.$store.commit('drillsV3/setPageType', PAGE_TYPE.DRILL)
    let sProblemIds = []
    try {
      const paramSearch = this.$route.query.sProblemIds
      //@ts-ignore
      sProblemIds = JSON.parse(paramSearch)
    } catch {
      sProblemIds = []
    }
    if (!sProblemIds?.length) {
      this.error = true
      return
    }
    // 表示用の情報を取得し、ストアに保持
    const problems = await this.getProblems([sProblemIds], version)
    await this.$store.dispatch('drillsV3/setProblems', problems)
    this.loadComplete = true

    Vue.prototype.$loading.complete()
  }
}
